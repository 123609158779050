<template>
  <div>
    <v-container class="mkt-content-container mt-10">
      <v-row class="body-2 mb-3">
        <v-col cols="12">
          <router-link to="/home">
            Home
          </router-link>
          >
          <span>
            Central de ajuda
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <span class="display-1 text--primary--color font-weight-bold">
            Precisa de ajuda?
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          Nossos canais de atendimento funcionam de segunda à sexta, das 8:00 as 18:00.
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col col="12" sm="6" md="4">
          <v-card class="rounded-card" min-height="250px">
            <v-card-text class="text--primary">
              <div class="d-flex flex-column align-center">
                <div>
                  <v-icon x-large color="primary">
                    mdi-whatsapp
                  </v-icon>
                </div>
                <div class="title font-weight-bold text--primary--color mt-2">
                  +55 (48) 98439-4452
                </div>
              </div>
              <div class="mt-5 text-center">
                Para tirar dúvidas de forma rápida e prática.
              </div>
              <div class="mt-10 text-center">
                De segunda a sexta das 8h às 18h.
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col col="12" sm="6" md="4">
          <v-card class="rounded-card" min-height="250px">
            <v-card-text class="text--primary">
              <div class="d-flex flex-column align-center">
                <div>
                  <v-icon x-large color="primary">
                    mdi-mail
                  </v-icon>
                </div>
                <div class="title font-weight-bold text--primary--color mt-2">
                  contato@proxpect.com.br
                </div>
              </div>
              <div class="mt-5 text-center">
                Para tirar dúvidas de forma rápida e prática.
              </div>
              <div class="mt-10 text-center">
                De segunda a sexta das 8h às 18h.
                Resposta em até um dia útil.
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col col="12" sm="6" md="4">
          <v-card class="rounded-card" min-height="250px">
            <v-card-text
              class="text--primary">
              <div class="d-flex flex-column align-center">
                <div>
                  <v-icon x-large color="primary">
                    mdi-phone
                  </v-icon>
                </div>
                <div class="title font-weight-bold text--primary--color mt-2">
                  (48) 99212-0020
                </div>
              </div>
              <div class="mt-5 text-center">
                Para suporte e vendas
              </div>
              <div class="mt-10 text-center">
                De segunda a sexta das 8h às 18h.
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>
